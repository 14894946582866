<template>
  <div class="box">
    <div class="contnier main-warp">
      <div class="flex justify-between" style="width: 100%">
        <el-row :gutter="100" style="width: 100%">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="m-b-20">
            <title-bar :urlIndex="1" title="项目中介超市"></title-bar>
            <div class="boxs" style="height: 398px;width: 100%;">
              <el-row style="padding-bottom: 20px">
                <el-col :span="6" class="hei">项目名称</el-col>
                <el-col :span="6" class="hei">地点</el-col>
                <el-col :span="6" class="hei">类型</el-col>
                <el-col :span="6" class="hei">发布日期</el-col>
              </el-row>
              <el-row class="pointer hoverClass" style="padding: 20px 0;border-top: 1px dashed #DDDDDD" v-for="(item,index) in list" :key="item.id"  v-if="index<=4">
                <div @click="gotoDetail(item.id)">
                  <el-col :span="6" class="hui ellipsis">{{item.title}}</el-col>
                  <el-col :span="6" class="hui ellipsis">{{item.area}}</el-col>
                  <el-col :span="6" class="hui ellipsis">{{item.category.name}}</el-col>
                  <el-col :span="6" class="hui ellipsis">{{item.createtime|formatDate}}</el-col>
                </div>
              </el-row>
              <el-row style="padding-top: 20px;border-top: 1px dashed #DDDDDD;cursor: pointer" v-if="list.length>=6">
                <el-col :span="24" class="hui text-center" >
                  <div @click="goToUrl('/projectRelease')">
                    查看更多<i class="el-icon-arrow-right"></i>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <title-bar :urlIndex="2" title="项目招标模块" ></title-bar>
            <div class="boxs" style="height: 398px;width: 100%;">
              <el-row style="padding-bottom: 20px">
                <el-col :span="6" class="hei pointer">
                  <div @click="zhaobiaoList(1)" :style="{fontWeight:typeIndex == 1?'700':'500'}">
                    招标公告
                  </div>
                </el-col>
                <el-col :span="6"  :offset="12" class="hei pointer">
                  <div @click="zhaobiaoList(2)" :style="{fontWeight:typeIndex == 2?'700':'500'}">
                    中标公示
                  </div>
                </el-col>
              </el-row>
              <el-row class="pointer" style="padding: 20px 0;border-top: 1px dashed #DDDDDD" v-for="(item,index) in zhaobiao" :key="item.id" v-if="index<=4">
                <el-col :span="24" class="hui"><div class=" ellipsis" @click="goZhao(item.id)" >{{item.title}}</div></el-col>
              </el-row>
              <div v-if="zhaobiao.length==0">
                <el-empty description="暂无招标"></el-empty>
              </div>
              <el-row style="padding-top: 20px;border-top: 1px dashed #DDDDDD;cursor: pointer" v-if="zhaobiao.length>5">
                <el-col :span="24" class="hui text-center" >
                  <div @click="goToUrl('/trademark')">
                    查看更多<i class="el-icon-arrow-right"></i>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 80px">
        <el-row :gutter="100">
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" class="m-b-20">
            <div>
              <title-bar :urlIndex="3" title="人才超市模块"></title-bar>
              <div style="margin-top: 38px">
                <div class="cardList" @click="goToUrl('/humanResources')" style="cursor: pointer">
                  <div class="flex align-center justify-center bordLi">
                    <div style="margin-right: 30px">
                      <el-image fit="cover" style="width: 100%;":src="require('@/assets/images/index/rencai1.png')"></el-image>
                    </div>
                    <div class="text-center">
                      <div class="hezuo">资源合作</div>
                      <div class="gongzuo">找工作</div>
                    </div>
                  </div>
                  <div class="flex align-center justify-center bordLi">
                    <div style="margin-right: 30px">
                      <el-image fit="cover" style="width: 100%;":src="require('@/assets/images/index/rencai2.png')"></el-image>
                    </div>
                    <div class="text-center">
                      <div class="hezuo">项目合作</div>
                      <div class="gongzuo">找人才</div>
                    </div>
                  </div>
                  <div class="flex align-center justify-center bordLi">
                    <div style="margin-right: 30px">
                      <el-image fit="cover" style="width: 100%;":src="require('@/assets/images/index/rencai3.png')"></el-image>
                    </div>
                    <div class="text-center">
                      <div class="hezuo">技术合作</div>
                      <div class="gongzuo">专业人才</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
            <div>
              <title-bar :urlIndex="4" title="机构组织" :isright="true"  @gotourl="goToUrl('/entInfoList')"></title-bar>
              <div style="margin-top: 38px">
                <el-row :gutter="20"  v-if="newsType.length>=2">
                  <el-col :span="11">
                    <div class="cardImage pointer" @click="gojigou(newsType[0].id)">
                      <el-image fit="cover" style="width: 100%;":src="require('@/assets/images/index/ruanjina.png')"></el-image>
                      <div class="textImage">{{newsType[0].name}}</div>
                    </div>
                  </el-col>
                  <el-col :span="11" :offset="2">
                    <div  class="cardImage pointer" @click="gojigou(newsType[1].id)">
                      <el-image fit="cover" style="width: 100%;":src="require('@/assets/images/index/project.png')"></el-image>
                      <div class="textImage">{{newsType[1].name}}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 80px" >
        <title-bar :urlIndex="5" title="知识资讯" :isright="true"  @gotourl="goToUrl('/zhixun')"></title-bar>
        <div style="margin-top: 38px">
          <el-row :gutter="20">
            <transition name="el-zoom-in-center">
              <el-col :span="9" v-if="newList.length>0&&newList[activeIndex].title">
                <div>
                  <el-image fit="cover" style="width: 100%;height: 300px;":src="newList[activeIndex].image">
                    <div slot="error" class="image-slot flex align-center justify-center"  style="width: 100%;height: 300px;background-color: #f5f7fa">
                      <div class="font-size-18" style="color: #dddddd">图片加载失败</div>
                    </div>
                  </el-image>
                </div>
                <div class="pointer" style="margin: 18px 0 14px 0;font-size: 22px;font-weight: 400;"  @click.stop="gotoDetails(newList[activeIndex].id)">
                  {{newList[activeIndex].title||''}}
                </div>
                <div class="ellipsis-multiline" style="font-weight: 400;font-size: 16px;color: #666666;">
                  {{newList[activeIndex].content||''}}
                </div>
              </el-col>
            </transition>
            <el-col :offset="1" :span="14">
              <div class="xinwen flex justify-between" v-for="(item,index) in newList" :key="item.id" @mouseenter="handleMouseEnter(index)">
                <div class="dot"  :style="{backgroundColor:activeIndex == index?'#007BFC':''}"></div>
                <div class="titleX">
                  <div class="hovertitle pointer" :style="{color:activeIndex == index?'#007BFC':''}" style="font-size: 18px;font-weight: 400;" @click.stop="gotoDetails(item.id)">{{item.title}}</div>
                  <div class="ellipsis-multiline" style="font-weight: 400;font-size: 16px;color: #666666;margin-top: 10px">
                    {{item.content}}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleBar from "@/views/components/titleBar.vue";
import {category} from "@/views/user/api";
import {newsList, projectPerson, zhaobiaoList} from "@/views/api";
import {formatDate} from "@/utils/validate";
import {mapState} from "vuex";

export default {
  name: 'HomeView',
  components: {TitleBar},
  data(){
    return {
      url:'@/assets/images/icon/1.png',
      newsType:[],
      newList:[],
      activeIndex:0,
      zhaobiao:[],
      list:[],
      typeIndex:1,
      page:1,
      limit:10
    }
  },
  created() {
    this.newCategory()
    this.newsList()
    this.zhaobiaoList(1)
    this.projectPerson()
  },
  filters: {
    formatDate(time) {
      if (time !== 0) {
        let date = new Date(time * 1000);
        return formatDate(date, 'yyyy-MM-dd hh:mm');
      }
    }
  },
  computed: {
    ...mapState('userInfo',['userInfo']),
  },
  methods:{
    gojigou(e){
      this.$router.push({
        path:'/entInfoList',
        query:{
          id:e
        }
      })
    },
    projectPerson(){
      let data = {
        page:1,
        limit:10,
      }
      projectPerson(data).then(res => {
        this.list = res.data.data
      })
    },
    zhaobiaoList(e){
      this.typeIndex = e
      let data = {
        page:this.page,
        limit:this.limit,
        type:e
      }
      zhaobiaoList(data).then(res => {
        this.zhaobiao = res.data.data
      })
    },
    handleMouseEnter(e){
      this.activeIndex = e
    },
    newsList(){
      let data = {
        page:this.page,
        limit:this.limit,
      }
      newsList(data).then(res => {
        this.newList = res.data.data||[]
      })
    },
    newCategory(){
      category({is_recommend:1}).then(res => {
        this.newsType = res.data || []
      })
    },
    goZhao(e){
      this.$router.push({
        path:'/trademark/details',
        query:{
          id:e
        }
      })
    },
    gotoDetails(e){
      this.$router.push({
        path:'/zhixunDetail',
        query:{
          id:e
        }
      })
    },
    gotoDetail(e){
      this.$router.push({
        path:'/projectRelease/personDetail',
        query:{
          id:e
        }
      })
    },
    goToUrl(e){
      this.$router.push(e)
    }
  }
}
</script>

<style lang="scss"  scoped>
.xinwen{
  border-top: 1px solid #ccc;
  padding-top: 8px;
  padding-bottom: 8px;
  .dot{
    width: 12px;
    height: 12px;
    background-color: #000;
    border-radius: 100%;
    margin-top: 9px;
  }
  .titleX{
    width: 96%;
  }
}
.xinwen:first-child{
  border-top:none;
}
.xinwen:hover .dot{
  background-color: #007BFC;
  color: #007BFC;
}
.xinwen:hover .hovertitle{
  color: #007BFC;
}
.hoverClass:hover .hui{
  color: #007BFC !important;
}
.cardImage{
  position: relative;
  width: 88%;
  .textImage{
    position: absolute;
    bottom: 1%;
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 500;
    height: 38%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient( 180deg, rgba(0,0,0,0.1) 0%, #000000 100%);
  }
}
.cardList{
  .bordLi{
    box-shadow: 0px 4px 20px 0px rgba(55,95,241,0.2);
    border-radius: 5px 5px 5px 5px;
    background: #FFFFFF;
    padding: 20px 0;
    width: 94%;
    margin-top: 30px;
  }
  .hezuo{
    color: #333333;
    font-size: 18px;
  }
  .gongzuo{
    color: #666666;
    font-size: 16px;
    margin-top: 10px;
  }
}
.hei{
  font-size: 18px;
  color: #333333;
  font-weight: 500;
}
.hui{
  font-size: 16px;
  color: #636363;
  font-weight: 500;
  &:hover{
    color: #007BFC;
  }
}
.box{
  .contnier{
    margin-top: 100px;
    .boxs{
      width: 88%;
      border: 2px solid #DDDDDD;
      padding: 30px 22px;
      margin-top: 38px;
      border-radius: 10px;
    }
  }
}
</style>
