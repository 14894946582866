<template>
  <div>
    <el-container>
      <el-header>
        <nav-list></nav-list>
      </el-header>
      <el-main style="position:relative;">
        <transition name="el-zoom-in-center">
          <router-view/>
        </transition>
        <div style="position: fixed;z-index: 999;top: 77%;right: 1.8%" @click="gotowebstock" v-if="getCookies('token')">
          <div>
            <el-image style="width: 50px;":src="require('@/assets/images/icon/liaotian.png')"></el-image>
          </div>
        </div>
      </el-main>
      <el-footer>
        <footers></footers>
      </el-footer>
    </el-container>
    <el-drawer
      title="提示"
      :with-header="false"
      :visible.sync="dialogVisible"
      width="10%"
    >
      <div style="height: 99%">
        <iframe width="98%" style="height: 100%" name="客服" :src="iframeUrl" title="W3School 在线教程">
        </iframe>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import navList from "./navList.vue";
import footers from "./footers.vue";
import {mapState} from "vuex";
import {getCookies} from "@/libs/util";

export default {
  components: {
    navList,
    footers
  },
  data(){
    return{
      dialogVisible:false,
      iframeUrl:''
    }
  },
  computed: {
    ...mapState('userInfo',['userInfo']),
  },
  methods:{
    getCookies,
    gotowebstock(){
      console.log(this.userInfo)
      this.dialogVisible = true
      let url = `https://ynshuanghe.cn/h5?token=${this.userInfo.im_token}&auth_token=${this.userInfo.auth_token}&avatar=${this.userInfo.avatar}&user_id=${this.userInfo.user_id}&nickname=${this.userInfo.nickname}&fastId=${this.userInfo.fastim_id}`
      this.iframeUrl = url
      console.log(url)
    },
  }
}
</script>

<style>
body{
  margin: 0;
  padding: 0;
}
</style>

<style lang="scss" scoped>
::v-deep .el-main{
  padding: 0 !important;
  margin-top: 400px !important;
}
.el-header{
  padding: 0;
}
.el-footer{
  padding: 0;
}
</style>

