import request from "@/libs/request";

export function userInfo() {
    return request({
        url: '/user/index',
        method: 'get'
    });
}

// /user/profile
export function userProfile(data) {
    return request({
        url: '/user/profile',
        method: 'post',
        data
    });
}

// user/changePassword
export function changePassword(data) {
    return request({
        url: '/user/changePassword',
        method: 'post',
        data
    });
}

// /user/getRealStatus
export function getRealStatus() {
    return request({
        url: '/user/getRealStatus',
        method: 'get'
    });
}

// /vip/getConfig
export function getConfig() {
    return request({
        url: '/vip/getConfig',
        method: 'get'
    });
}

// /vip/recharge
export function recharge(data) {
    return request({
        url: '/vip/recharge',
        method: 'post',
        data
    });
}

// /vip/check
export function checkStauts(data) {
    return request({
        url: '/vip/check',
        method: 'post',
        data
    });
}

// user/real
export function userReal(data) {
    return request({
        url: '/user/real',
        method: 'post',
        data
    });
}

// 企业分类/company/category
export function category(params) {
    return request({
        url: '/company/category',
        method: 'get',
        params
    });
}

// 企业列表/company/category
export function categoryList(params) {
    return request({
        url: '/company/list',
        method: 'get',
        params
    });
}

// 企业信息/company/my
export function companydetail(params) {
    return request({
        url: '/company/detail',
        method: 'get',
        params
    });
}

// /company/performance
export function companyperformance(params) {
    return request({
        url: '/company/performance',
        method: 'get',
        params
    });
}

export function company(params) {
    return request({
        url: '/company/my',
        method: 'get',
        params
    });
}

// 修改企业/company/editCompany
export function editCompany(data) {
    return request({
        url: '/company/editCompany',
        method: 'post',
        data
    });
}

// /company/performance
export function performance(params) {
    return request({
        url: '/company/performance',
        method: 'get',
        params
    });
}

// /company/savePerformance
export function savePerformance(data) {
    return request({
        url: '/company/savePerformance',
        method: 'post',
        data
    });
}

// /company/delPerformance
export function delPerformance(data) {
    return request({
        url: '/company/delPerformance',
        method: 'post',
        data
    });
}

// /job/my
export function jobMy(params) {
    return request({
        url: '/job/my',
        method: 'get',
        params
    });
}

// job/editInfo
export function editInfo(data) {
    return request({
        url: '/job/editInfo',
        method: 'post',
        data
    });
}

// /job/saveExperience
export function saveExperience(data) {
    return request({
        url: '/job/saveExperience',
        method: 'post',
        data
    });
}

// /job/delExperience
export function delExperience(data) {
    return request({
        url: '/job/delExperience',
        method: 'post',
        data
    });
}

// job/saveJob
export function saveJob(data) {
    return request({
        url: '/job/saveJob',
        method: 'post',
        data
    });
}

// job/delJob
export function delJob(data) {
    return request({
        url: '/job/delJob',
        method: 'post',
        data
    });
}

// position/my
export function positionMy() {
    return request({
        url: '/position/my',
        method: 'get'
    });
}

// position/save
export function positionSave(data) {
    return request({
        url: '/position/save',
        method: 'post',
        data
    });
}

// position/del
export function positionDel(data) {
    return request({
        url: '/position/del',
        method: 'post',
        data
    });
}

// project/biding
export function projectBiding(params) {
    return request({
        url: '/project/biding',
        method: 'get',
        params
    });
}

// project/my
export function projectBidinUs(params) {
    return request({
        url: '/project/my',
        method: 'get',
        params
    });
}

// /project/bidPeople
export function projectbidPeople(params) {
    return request({
        url: '/project/bidPeople',
        method: 'get',
        params
    });
}

// project/setBid
export function positionSetBid(data) {
    return request({
        url: '/project/setBid',
        method: 'post',
        data
    });
}

// /project/bided
export function projectbidBided(params) {
    return request({
        url: '/project/bided',
        method: 'get',
        params
    });
}

export function newsMy(params) {
    return request({
        url: '/news/my',
        method: 'get',
        params
    });
}

export function userRecord(params) {
    return request({
        url: '/user/record',
        method: 'get',
        params
    });
}

