<template>
  <div style="padding-top: 72px;background-color: #0A1733;height: 300px !important;margin-top: 200px;">
    <div class="main-warp flex justify-between">
      <div class="left">
        <div class="witr flex">
          <el-image :src="baseInfo.logo" style="width: 50px;height: 50px"></el-image> &nbsp;&nbsp;&nbsp;&nbsp; {{baseInfo.company_name}}
        </div>
        <div class="hui">{{baseInfo.beian}}</div>
        <div class="hui">{{baseInfo.company_name}}</div>
        <div class="hui">tel:{{baseInfo.mobile}}</div>
      </div>
      <div class="right">
        <div class="left pointerw w200" @click="goTabar('/trademark',1)">
          <div class="witr">
            招投标板块
          </div>
          <div class="hui">选择类型</div>
          <div class="hui">选择时间</div>
          <div class="hui">项目列表</div>
        </div>
        <div class="left pointer w200" @click="goTabar('/projectRelease',2)">
          <div class="witr">
            项目外包板块
          </div>
          <div class="hui">我要发布</div>
          <div class="hui">寻找项目</div>
        </div>
        <div class="left pointer w200" @click="goTabar('/humanResources',3)">
          <div class="witr">
            人力资源板块
          </div>
          <div class="hui">找人才</div>
          <div class="hui">找工作</div>
          <div class="hui">岗位列表</div>
        </div>
        <div class="left pointer w200" @click="goTabar('/zhixun')">
          <div class="witr">
            新闻资讯板块
          </div>
          <div class="hui">新闻列表</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      baseInfo:{}
    }
  },
  created() {
    this.baseInfo = JSON.parse(localStorage.getItem('config'))
  },
  methods:{
    goTabar(e){
      this.$router.push(e)
    }
  }
}
</script>

<style scoped lang="scss">
.left{
  width: 24%;
}
.w200{
  width: 200px !important;
}
.right{
  width: 68%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.witr{
  color: #ffffff;
  margin-bottom: 29px;
  font-size: 18px;
  &:hover{
    color: #70c1fa;
  }
}
.hui{
  color: #6D7484;
  font-size: 16px;
  margin-bottom: 10px;
  &:hover{
    color: #fff;
  }
}

</style>
