<template>
  <div>
    <div class="top">
      <div class="main-warp flex" style="height: 100%">
        <div class="content flex">
          <div class="flex align-center color webName" style="width: 18%;">
            <div style="margin-right: 26px">
              <el-image :src="logo" style="width: 50px;height: 50px"></el-image>
            </div>
            <div class="text-center">{{webName}}</div>
          </div>
          <div class="tabList color flex">
            <el-menu :default-active="activePath||activeIndex" :router="true" class="el-menu-demo" mode="horizontal" active-text-color="#FFFFFF" text-color="#FFFFFF" background-color="#007BFC">
              <el-menu-item :index="item.url"  v-for="(item,index) in list"  :key="index" class="font-size-16">{{item.label}}</el-menu-item>
            </el-menu>
            <div class="flex align-center m-r-20 pointer clickopen" @click="clickOpen">
              <i class="el-icon-s-operation font-size-26" style="color: #FFFFFF"></i>
            </div>
          </div>
        </div>
        <div class="admin flex align-center justify-end">
          <div>
            <div class="btn" v-if="!getUserInfos" @click="goLogin">登录</div>
            <el-dropdown :show-timeout="70" @command="onDropdownCommand" v-else>
              <span class="layout-navbars-breadcrumb-user-link" style="color:#FFFFFF;">
                <el-avatar shape="square" size="medium" :src="getUserInfos.avatar" style="margin-right: 10px"></el-avatar>
                <span class="avatarFlag">
                  {{ getUserInfos.nickname }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="user">个人中心</el-dropdown-item>
                <el-dropdown-item divided command="logOut">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <transition name="el-zoom-in-top">
        <div class="open" v-show="isOpen">
          <div class="navLink pointer" v-for="i in list" :key="i.value" @click="goToUrl(i)">{{i.label}}</div>
        </div>
      </transition>
    </div>
    <div class="box" style="position: relative">
      <div class="flex justify-between" style="width: 100%;">
        <el-carousel height="400px" style="width: 100%;" direction="vertical" :interval="8000"  :autoplay="true">
          <el-carousel-item v-for="(item,index) in banner" :key="index">
            <el-image fit="fill" :src="item" style="width: 100%;height: 100%"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {getConfig, logouted} from "@/libs/api"
import { removeCookies } from '@/libs/util';
import router from "@/router";

export default {
  name:'navBar',
  props:{
    indexUser:{
      type:Number,
      default:0
    },
    isFlag:{
      type:Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen:false,
      activeIndex:this.$route.path,
      indexed: 1,
      list: [
        {
          label:'首页',
          value:1,
          url:'/index'
        },
        {
          label:'招商标',
          value:2,
          url:'/trademark'
        },
        {
          label:'项目发布',
          value:3,
          url:'/projectRelease'
        },
        {
          label:'人力资源',
          value:4,
          url:'/humanResources'
        },
        {
          label:'新闻咨询',
          value:6,
          url:'/zhixun'
        },
        {
          label:'平台介绍',
          value:5,
          url:'/introduce'
        }
      ],
      logo:'',
      banner:'',
      webName:'网络技术开发平台'
    }
  },
  created() {
    this.getConfig()
  },
  computed: {
    ...mapState('userInfo', ['activePath']),
    // 获取用户信息
    getUserInfos() {
      return this.$store.state.userInfo.userInfo;
    },
  },
  methods:{
    goToUrl(e){
      this.indexed = e.value
      this.$router.push({
        path:e.url
      })
      setTimeout(()=>{
        this.isOpen = false
      },500)
    },
    clickOpen(){
      console.log(this.isOpen)
      this.isOpen = !this.isOpen
    },
    getConfig(){
      getConfig().then(res => {
        localStorage.setItem('config',JSON.stringify(res.data))
        this.logo = res.data.logo
        this.banner = res.data.banner
        if(res.data.banner.length>0){
          this.banner = res.data.banner.map(item => {
            return  'https://ynshuanghe.cn'+item
          })
        }
        this.webName = res.data.company_name
      })
    },
    goLogin(){
      this.$router.push('/login')
    },
    onDropdownCommand(e){
      if(e == 'logOut'){
        this.$confirm('是否退出登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          logouted().then(res => {
            this.$message.success('您已成功退出');
            this.$store.commit('userInfo/clearAll');
            removeCookies('token');
            removeCookies('expires_time');
            removeCookies('id');
            router.replace({ name: 'login' });
          })
        }).catch(() => {

        });
      }else{
        this.$router.push('/user')
      }
    },
  }
}

</script>

<style scoped lang="scss">
.open{
  position: absolute;
  width: 100%;
  height: 322px;
  background-color: #007BFC;
  z-index: 9999;
  .navLink{
    font-weight: 200;
    padding: 1vw;
    color: white;
    font-family: "微软雅黑";
    text-transform: capitalize;
    font-size: 20px;
    transition: all 0.25s ease;
    margin-left: 10px;
    text-align: center;
    &:hover{
      font-size: 24px;
    }
    &:active{
      font-size: 24px;
    }
  }
}
.box{
  //background-color: #007BFC;
  height: 400px;
  padding-top: 60px;
}
::v-deep .el-menu-item{
  padding: 0 36px !important;
}
.top{
  height: 60px;
  border-bottom: 1px solid #f4f4f4;
  background-color: #007BFC;
  position: fixed;
  width: 100%;
  z-index: 100;
  .content{
    height: 100%;
    width: 86%;
    .tabList{
      width: 82%;
      justify-content: space-around;
      .li{
        height: 58px;
        line-height: 60px;
        padding: 0 21px;
        border-bottom: 2px solid #fff;
        cursor: pointer;
      }
    }
  }
  .admin{
    width: 14%;
    .search{
      margin-right: 44px;
    }
  }
}
.color{
  color: #ffffff;
}
.clickopen{
  display: none;
}
/* 当视口宽度小于等于600px时，改变宽度 */
@media (max-width: 1200px) {
  .clickopen{
    display: block;
    margin: auto 0;
  }
  .avatarFlag{
    display: none;
  }
  .tabList{
    justify-content: end !important;
    padding-right: 20px;
  }
  .el-menu-demo {
    display: none !important;
  }
  .webName{
    width: 100% !important;
  }
}

.btn{
  width: 70px;
  padding: 6px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  color: #007BFC;
  cursor: pointer;
}
.layout-navbars-breadcrumb-user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &-link {
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    &-photo {
      width: 30px;
      height: 30px;
      border-radius: 100%;
    }
  }
  &-icon {
    padding: 0 10px;
    cursor: pointer;
    color: var(--prev-bg-topBarColor);
    height: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;
    &:hover {
      background: var(--prev-color-hover);
      i {
        display: inline-block;
        animation: logoAnimation 0.3s ease-in-out;
      }
    }
  }
  & ::v-deep .el-dropdown {
    color: var(--prev-bg-topBarColor);
    cursor: pointer;
  }
  & ::v-deep .el-badge {
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
  }
  & ::v-deep .el-badge__content.is-fixed {
    top: 12px;
  }
}
</style>
