<template>
  <div id="app">
    <el-backtop target="#app" :bottom="80"></el-backtop>
    <router-view/>
  </div>
</template>

<script>
export default {
  watch: {
    // 监听路由 控制侧边栏显示 标记当前顶栏菜单（如需要）
    $route(to, from) {
      const onRoutes = to.meta.activeMenu ? to.meta.activeMenu : to.meta.path;
      this.$store.commit('userInfo/setActivePath', onRoutes);
    },
  },
}
</script>

<style>
body{
  margin: 0;
  padding: 0;
  height: 100vh;
}
</style>

<style lang="scss" scoped>
#app{
  height: 100vh;
  overflow-x: auto;
  font-family: 'Microsoft YaHei', 'Arial', sans-serif;
}

::v-deep .el-card{
  border-radius: 0;
}
::v-deep .el-tag{
  border-radius: 0;
}

::v-deep .el-dialog__header{
  background-color: #007BFC;
  padding: 16px;
  .el-dialog__title{
    color: #FFFFFF !important;
  }
}
::v-deep .el-dialog{
  border-radius: 0 !important;
}
::v-deep .el-dialog__headerbtn .el-dialog__close{
  color: #FFFFFF;
}

::v-deep .el-button{
  border-radius: 0;
}

::v-deep .el-input__inner{
  border-radius: 0 !important;
}

::v-deep .el-textarea__inner{
  border-radius: 0 !important;
}

.el-header{
  padding: 0;
  height: 100% !important;
}
.el-main{
  padding: 0;
  height: 100% !important;
}
.el-footer{
  background-color: #0A1733;
  height: 400px !important;
}

.color-666{
  color: #666666;
}
</style>

