<template>
  <div class="flex align-center justify-between">
    <div class="flex align-center">
      <div style="width: 24px; height: 24px;margin-right: 15px">
        <el-image v-if="urlIndex == 1" style="width: 100%;":src="require('@/assets/images/icon/1.png')"></el-image>
        <el-image v-if="urlIndex == 2" style="width: 100%;":src="require('@/assets/images/icon/2.png')"></el-image>
        <el-image v-if="urlIndex == 3" style="width: 100%;":src="require('@/assets/images/icon/3.png')"></el-image>
        <el-image v-if="urlIndex == 4" style="width: 100%;":src="require('@/assets/images/icon/4.png')"></el-image>
        <el-image v-if="urlIndex == 5" style="width: 100%;":src="require('@/assets/images/icon/5.png')"></el-image>
      </div>
      <div style="font-size: 18px;font-weight: 600;color: #007BFC;">
        {{title}}
      </div>
    </div>
    <div class="flex align-center" style="width: 35px; height: 24px;cursor: pointer" v-if="isright" @click="gotourl">
      <el-image style="width: 100%;":src="require('@/assets/images/icon/arrorRight.png')"></el-image>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    isright:{
      type:Boolean,
      default:false
    },
    urlIndex:{
      type:Number,
      default:1
    },
    title:{
      type:String,
      default:''
    }
  },
  methods:{
    gotourl(){
      this.$emit('gotourl')
    }
  }
}
</script>

<style scoped lang="scss">

</style>
