import request from "./request.js";

//登录
export function logouted() {
    return request({
        url: '/user/logout',
        method: 'post',
    });
}

//登录 获取配置
export function getConfig() {
    return request({
        url: '/common/getConfig',
        method: 'get',
    });
}
