import Vue from 'vue'
import VueRouter from 'vue-router'
import Router from 'vue-router';
import HomeView from '../views/index/index.vue'
import LayoutMain from '@/layout';

Vue.use(VueRouter)

// 解决 `element ui` 导航栏重复点菜单报错问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    name: 'index',
    component: LayoutMain,
    redirect: '/index',
    children: [
      {
        path: '/index',
        meta:{
          path:'/index'
        },
        name: 'index',
        component: HomeView
      },
      {
        path: '/zhixun',
        name: 'zhixun',
        meta:{
          path:'/zhixun'
        },
        component: () => import('@/views/index/zhixun')
      },
      {
        path: '/zhixunAdd',
        name: 'zhixunAdd',
        meta:{
          path:'/zhixun'
        },
        component: () => import('@/views/index/zhixun/zhixunAdd')
      },
      {
        path: '/zhixunDetail',
        name: 'zhixunDetail',
        meta:{
          path:'/zhixun'
        },
        component: () => import('@/views/index/zhixun/zhixunDetail')
      },
      {
        path: '/entInfo',
        name: 'entInfo',
        meta:{
          path:'/index'
        },
        component: () => import('@/views/index/entInfo')
      },
      {
        path: '/entInfoList',
        name: 'entInfoList',
        meta:{
          path:'/index'
        },
        component: () => import('@/views/index/entInfo/entInfoList')
      },
      {
        path: '/webstock',
        name: 'webstock',
        meta:{
          path:'/index'
        },
        component: () => import('@/views/webstock')
      }
    ]
  },
  {
    path: '/trademark',
    name: 'trademark',
    component: LayoutMain,
    redirect: '/trademark/index',
    children: [
      {
        path: 'index',
        meta:{
          path:'/trademark'
        },
        name: 'index',
        component: () => import('@/views/trademark/index.vue'),
      },
      {
        path: 'details',
        meta:{
          path:'/trademark'
        },
        name: 'index',
        component: () => import('@/views/trademark/details.vue'),
      }
    ]
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: LayoutMain,
    redirect: '/introduce/index',
    children: [
      {
        path: 'index',
        meta:{
          path:'/introduce'
        },
        name: 'index',
        component: () => import('@/views/introduce'),
      }
    ]
  },
  {
    path: '/projectRelease',
    name: 'projectRelease',
    component: LayoutMain,
    redirect: '/projectRelease/index',
    children: [
      {
        path: 'index',
        meta:{
          path:'/projectRelease'
        },
        name: 'index',
        component: () => import('@/views/projectRelease/index.vue'),
      },
      {
        path: 'personDetail',
        meta:{
          path:'/projectRelease'
        },
        name: 'personDetail',
        component: () => import('@/views/projectRelease/personDetail'),
      },
      {
        path: 'entProjectAdd',
        meta:{
          path:'/projectRelease'
        },
        name: 'entProjectAdd',
        component: () => import('@/views/projectRelease/entProjectAdd'),
      }
    ]
  },
  {
    path: '/humanResources',
    name: 'humanResources',
    component: LayoutMain,
    redirect: '/humanResources/index',
    children: [
      {
        path: 'index',
        meta:{
          path:'/humanResources'
        },
        name: 'index',
        component: () => import('@/views/humanResources/index.vue'),
      }
    ]
  },
  {
    path: '/user',
    name: 'user',
    component: LayoutMain,
    redirect: '/user/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/user')
      },
      {
        path: 'renzheng',
        name: 'renzheng',
        component: () => import('@/views/user/renzheng/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/login/register')
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('@/views/login/forget.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
