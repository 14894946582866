import request from "@/libs/request";

//登录
export function logouted() {
    return request({
        url: '/user/logout',
        method: 'post',
    });
}

// 咨询 /news/category
export function newCategory() {
    return request({
        url: '/news/category',
        method: 'get',
    });
}

// 咨询列表/news/list
export function newsList(params) {
    return request({
        url: '/news/list',
        method: 'get',
        params
    });
}

// 咨询/news/save
export function newsSave(data) {
    return request({
        url: '/news/save',
        method: 'post',
        data
    });
}

export function newsDetail(params) {
    return request({
        url: '/news/detail',
        method: 'get',
        params
    });
}

// news/delete
export function newsDelete(data) {
    return request({
        url: '/news/delete',
        method: 'post',
        data
    });
}

// project/category
export function projectCategory() {
    return request({
        url: 'project/category',
        method: 'get'
    });
}

// /project/save
export function savePerson(data) {
    return request({
        url: '/project/savePerson',
        method: 'post',
        data
    });
}

// /project/person
export function projectPerson(params) {
    return request({
        url: 'project/person',
        method: 'get',
        params
    });
}

// project/detail
export function projectDetail(params) {
    return request({
        url: 'project/detail',
        method: 'get',
        params
    });
}

//
export function projectPersonBid(data) {
    return request({
        url: 'project/bid',
        method: 'post',
        data
    });
}

// project/saveCompany
export function saveCompany(data) {
    return request({
        url: 'project/saveCompany',
        method: 'post',
        data
    });
}

// project/company
export function company(params) {
    return request({
        url: 'project/company',
        method: 'get',
        params
    });
}

// position/list
export function positionList(params) {
    return request({
        url: 'position/list',
        method: 'get',
        params
    });
}

// /job/list
export function jobList(params) {
    return request({
        url: 'job/list',
        method: 'get',
        params
    });
}

// /zhaobiao/list
export function zhaobiaoList(params) {
    return request({
        url: 'zhaobiao/list',
        method: 'get',
        params
    });
}

// /zhaobiao/detail
export function zhaobiaoDetail(params) {
    return request({
        url: 'zhaobiao/detail',
        method: 'get',
        params
    });
}
